/* You can add global styles to this file, and also import other style files */
body
  font-family: 'Open Sans', sans-serif
  background-color: #eaeaea
  margin: 0
  overflow: hidden
  
  &.scrolling
    overflow: scroll
    height: 100vh

.box-shadow
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03)

.sr-only
  display: none
  
ngb-rating:focus
  outline: none
